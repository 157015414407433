<template>
    <!--<v-container fluid class="home" fill-height style="max-height: 100vh;">-->
    <v-container fluid class="bonus-background">
        <div v-if="param" :param="param">Форма операции</div>
        <bank-list v-else :paramPagination="true" :paramFilter="true" :paramTitle="true" :paramHeader="true"/>
    </v-container>
</template>

<script>
  import BankList from '@/components/BankList';
//  import PaypinForm from "../components/PaypinForm";

  export default {
    name: 'Banks',
//    props: {holderType: String},
    props: ['param'],
    components: {
      //PaypinForm,
      BankList
    },
    mounted() {
      //console.log(this.$route);
      //console.log(this.param);
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
